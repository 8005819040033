// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
function modal() {
  console.log("entra");
  const domElement = document.querySelectorById("openModal");
  let content = `<div>
      <a href="#close" title="Close" class="close">X</a>
      <h2>${date.day}</h2>
      </div>`;
  meetings.forEach((element) => {
    content += `<tr>
        <td>${element.name}</td>
        <td>${element.start_time}</td>
        <td><%= link_to 'Show', meeting ,class: 'meeting-a'%></td>
        <td><%= link_to 'Edit', edit_meeting_path(meeting),class: 'meeting-a' %></td>
        <td><%= link_to 'Destroy', calendar_index_url, method: :delete, data: { confirm: 'Are you sure?' } ,class: 'meeting-a'%></td>
      </tr>`;
  });
  domElement.innerHTML = html(content);
}
